<template>
  <HistoricalAssetMonthlyHealthDataStyled>
    <TalpaLoaderWrapper v-if="loading" />
    <template v-else>
      <template v-if="month && data.length">
        <TimePeriodSwitcherMolecule :date="month" :unit="'month'" @changeTimePeriod="onChangeTimePeriod" />
        <CustomChartAtom
          :data="seriesData()"
          :categories="categories"
          :types="types"
          :tooltip="tooltip"
          :xAxisLabelGenerator="xAxisLabelGenerator"
          @itemClick="onItemClick"
        />
      </template>
    </template>
  </HistoricalAssetMonthlyHealthDataStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import CustomChartAtom from '@/components/Atomic/Atoms/CustomChartAtom'
import { TalpaLoaderWrapper } from '@common/components'
import TimePeriodSwitcherMolecule from '../TimePeriodSwitcherMolecule.vue'
import chroma from 'chroma-js'
import { millisToHours, isDateInFuture, formatDate, millisFromIsoDate, getStartTime, getEndTime } from '@/utils/time'
import { DateTime } from 'luxon'
import { getLocale } from '@/utils/locale'

const HistoricalAssetMonthlyHealthDataStyled = styled('div')`
  height: 6rem;
  display: flex;
  flex-direction: column;
  .tooltip {
    line-height: 1.5rem;
  }
`
export default {
  inject: ['theme', 'uiSettings'],
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    assetId: {
      type: String,
      required: true,
    },
    month: {
      type: Object,
      required: true,
    },
    selectedDate: {
      type: Object,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      categories: ['Month'],
    }
  },
  components: {
    HistoricalAssetMonthlyHealthDataStyled,
    TalpaLoaderWrapper,
    CustomChartAtom,
    TimePeriodSwitcherMolecule,
  },
  computed: {
    types() {
      return [
        {
          name: 'HEALTHY',
          color: this.theme.colors.atomic.healthyFill,
          border: this.theme.colors.atomic.healthyBorder,
          stroke: this.theme.colors.atomic.healthyStroke,
          seperator: this.theme.colors.atomic.seperator,
          selection: this.theme.colors.atomic.selection,
          noData: this.theme.colors.atomic.noData,
        },
        {
          name: 'SICK',
          color: this.theme.colors.atomic.sickFill,
          border: this.theme.colors.atomic.sickBorder,
          stroke: this.theme.colors.atomic.sickStroke,
          seperator: this.theme.colors.atomic.seperator,
          selection: this.theme.colors.atomic.selection,
          noData: this.theme.colors.atomic.noData,
        },
        {
          name: 'UNKNOWN',
          color: this.theme.colors.atomic.unknownFill,
          border: this.theme.colors.atomic.unknownBorder,
          stroke: this.theme.colors.atomic.unknownBorder,
          seperator: this.theme.colors.atomic.seperator,
          selection: this.theme.colors.atomic.selection,
          noData: this.theme.colors.atomic.noData,
        },
      ]
    },
    tooltip() {
      return this.tootip()
    },
    durationPerDay() {
      return 24 * 60 * 60 * 1000
    },
  },
  methods: {
    onChangeTimePeriod(params) {
      this.$emit('onMonthChange', params)
    },
    trackEvent(trackingIdPrefix, health, value) {
      const trackingId = trackingIdPrefix + health
      this.$matomo?.trackEvent('Buttons', 'Click', trackingId, value)
    },
    onItemClick(params) {
      const health = params.name.toLowerCase()
      const trackingData = {
        from: params.healthData.start,
        to: params.healthData.end,
      }

      this.trackEvent(`Historical-Asset-Health-Monthly-`, health || '', trackingData)
      const start = getStartTime(params.healthData.start, 'day').toISO()
      const end = getEndTime(start, 'day').toISO()
      this.$emit('onDateSelection', { ...params, start, end })
    },
    hasData(date, healthData) {
      return isDateInFuture(date) || healthData.status === 'UNKNOWN'
    },
    getErrorCount(severities) {
      const errorSeverity = severities.find(severity => severity.severity.toLowerCase() === 'red')
      return errorSeverity ? errorSeverity.count : 0
    },
    getWarningCount(severities) {
      const warningSeverity = severities.find(severity => severity.severity.toLowerCase() === 'yellow')
      return warningSeverity ? warningSeverity.count : 0
    },
    tootip() {
      return {
        borderColor: 'transparent',
        backgroundColor: chroma(this.theme.colors.solidBG).alpha(1).css(),
        textStyle: {
          fontSize: 14,
          color: this.theme.colors.text,
        },
        cursor: 'default',
        formatter: params => {
          const errorCount = this.getErrorCount(params.data.healthData.severities)
          const warningCount = this.getWarningCount(params.data.healthData.severities)
          const sickDuration = millisToHours(params.data.healthData.duration)
          const sickDurationLabel = this.$t('historicalAssetHealth.tooltip.sickPeriods')
          const errorLabel = this.$tc('historicalAssetHealth.tooltip.errors', errorCount)
          const warningLabel = this.$tc('historicalAssetHealth.tooltip.warnings', warningCount)
          return params.data.name !== 'SICK'
            ? null
            : `
          <div class="tooltip">
                <strong>${formatDate(params.data.healthData.start, getLocale(this.uiSettings.dates))}</strong>
                ${errorCount > 0 || warningCount > 0 ? `<div>${sickDurationLabel}: ${sickDuration} </div>` : ''}
                ${errorCount > 0 ? `<div>${errorLabel}: ${errorCount}</div>` : ''}
                ${warningCount > 0 ? `<div>${warningLabel}: ${warningCount}</div>` : ''}
          </div>
        `
        },
      }
    },
    isSelectedDate(selectedDate, healthData, firstSickDayIndex, index) {
      if (!selectedDate) {
        return firstSickDayIndex === index
      }
      return DateTime.fromISO(selectedDate).day === DateTime.fromISO(healthData.start).day
    },
    seriesData() {
      const data = []
      const firstSickDayIndex = this.data.findIndex(healthData => healthData.status.toUpperCase() === 'SICK')
      this.categories.forEach(() => {
        for (let i = 0; i < this.data?.length; i++) {
          const healthData = this.data[i]
          const typeItem = this.types.find(type => type.name === healthData.status.toUpperCase())
          const totalDays = 1 //getTotalDaysBetweenDates(healthData.start, healthData.end)
          const baseTime = millisFromIsoDate(healthData.start)
          data.push({
            name: typeItem.name,
            value: [0, baseTime, baseTime + totalDays * this.durationPerDay, this.durationPerDay],
            isFuture: this.hasData(new Date(baseTime), healthData),
            healthData,
            isSelected: this.isSelectedDate(this.selectedDate?.start, healthData, firstSickDayIndex, i),
            itemStyle: {
              color: this.hasData(new Date(baseTime), healthData) ? typeItem.noData : typeItem.color,
              stroke: typeItem.stroke,
              seperator: typeItem.seperator,
              selection: typeItem.selection,
              noData: typeItem.noData,
            },
          })
        }
      })
      return data
    },
    xAxisLabelGenerator(params) {
      return DateTime.fromISO(params.healthData.start).day
    },
  },
}
</script>
