<template>
  <HistoricalAssetDailyHealthDataStyled>
    <TalpaLoaderWrapper v-if="loading" />
    <TimePeriodSwitcherMolecule
      v-if="!loading"
      :date="dayOfMonth"
      :unit="'day'"
      @changeTimePeriod="onChangeTimePeriod"
      :validDates="sickDaysOfTheMonth"
    />
    <CustomChartAtom
      v-if="!loading"
      :data="seriesData()"
      :categories="categories"
      :types="types"
      :tooltip="tooltip"
      :xAxisLabelGenerator="xAxisLabelGenerator"
      @itemClick="onItemClick"
    />
  </HistoricalAssetDailyHealthDataStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import CustomChartAtom from '@/components/Atomic/Atoms/CustomChartAtom'
import { TalpaLoaderWrapper } from '@common/components'
import TimePeriodSwitcherMolecule from '@/components/Atomic/Molecules/TimePeriodSwitcherMolecule'
import { isDateInFuture, millisFromIsoDate, formatTimePerLocale, getStartTime, getEndTime } from '@/utils/time'
import chroma from 'chroma-js'
import { DateTime } from 'luxon'
import { getLocale } from '@/utils/locale'

const HistoricalAssetDailyHealthDataStyled = styled('div')`
  height: 6rem;
  display: flex;
  flex-direction: column;
  .tooltip {
    text-align: left;
    line-height: 1.5rem;
  }
`
export default {
  inject: ['theme', 'uiSettings'],
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    assetId: {
      type: String,
      required: true,
    },
    dayOfMonth: {
      type: Object,
      required: true,
    },
    selectedHour: {
      type: Object,
    },
    data: {
      type: Array,
      required: true,
    },
    sickDaysOfTheMonth: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      categories: ['Day'],
    }
  },
  components: {
    HistoricalAssetDailyHealthDataStyled,
    TalpaLoaderWrapper,
    TimePeriodSwitcherMolecule,
    CustomChartAtom,
  },
  computed: {
    tooltip() {
      return this.tootip()
    },
    types() {
      return [
        {
          name: 'HEALTHY',
          color: this.theme.colors.atomic.healthyFill,
          border: this.theme.colors.atomic.healthyBorder,
          stroke: this.theme.colors.atomic.healthyStroke,
          seperator: this.theme.colors.atomic.seperator,
          selection: this.theme.colors.atomic.selection,
          noData: this.theme.colors.atomic.noData,
        },
        {
          name: 'SICK',
          color: this.theme.colors.atomic.sickFill,
          border: this.theme.colors.atomic.sickBorder,
          stroke: this.theme.colors.atomic.sickStroke,
          seperator: this.theme.colors.atomic.seperator,
          selection: this.theme.colors.atomic.selection,
          noData: this.theme.colors.atomic.noData,
        },
        {
          name: 'UNKNOWN',
          color: this.theme.colors.atomic.unknownFill,
          border: this.theme.colors.atomic.unknownBorder,
          stroke: this.theme.colors.atomic.unknownBorder,
          seperator: this.theme.colors.atomic.seperator,
          selection: this.theme.colors.atomic.selection,
          noData: this.theme.colors.atomic.noData,
        },
      ]
    },
    durationPerHour() {
      return 60 * 60 * 1000
    },
  },
  methods: {
    onChangeTimePeriod(params) {
      this.$emit('onDayChange', params)
    },
    trackEvent(trackingIdPrefix, health, value) {
      const trackingId = trackingIdPrefix + health
      this.$matomo?.trackEvent('Buttons', 'Click', trackingId, value)
    },
    onItemClick(params) {
      const health = params.name.toLowerCase()
      const trackingData = {
        from: params.healthData.start,
        to: params.healthData.end,
      }

      this.trackEvent(`Historical-Asset-Health-Daily-`, health || '', trackingData)
      const start = getStartTime(params.healthData.start, 'hour').toISO()
      const end = getEndTime(start, 'hour').toISO()
      this.$emit('onHourSelection', { ...params, start, end })
    },
    hasData(date, healthData) {
      return isDateInFuture(date) || healthData.status === 'UNKNOWN'
    },
    getErrorCount(severities) {
      const errorSeverity = severities.find(severity => severity.severity.toLowerCase() === 'red')
      return errorSeverity ? errorSeverity.count : 0
    },
    getWarningCount(severities) {
      const warningSeverity = severities.find(severity => severity.severity.toLowerCase() === 'yellow')
      return warningSeverity ? warningSeverity.count : 0
    },
    formatDate(startDate, endDate) {
      const startDateTime = DateTime.fromISO(startDate)
      const endDateTime = DateTime.fromISO(endDate)
      const locale = getLocale(this.uiSettings?.dates)

      const formattedStartDate = startDateTime.setLocale(locale).toFormat('dd MMM yyyy')

      let formattedTime = `${formatTimePerLocale(startDateTime, this.uiSettings.time, locale)} - ${formatTimePerLocale(
        endDateTime,
        this.uiSettings.time,
        locale,
      )}`
      return `${formattedStartDate}, ${formattedTime}`
    },
    tootip() {
      return {
        borderColor: 'transparent',
        backgroundColor: chroma(this.theme.colors.solidBG).alpha(1).css(),
        textStyle: {
          fontSize: 14,
          color: this.theme.colors.text,
        },
        formatter: params => {
          const errorCount = this.getErrorCount(params.data.healthData.severities)
          const warningCount = this.getWarningCount(params.data.healthData.severities)
          const errorLabel = this.$tc('historicalAssetHealth.tooltip.errors', errorCount)
          const warningLabel = this.$tc('historicalAssetHealth.tooltip.warnings', warningCount)

          return params.data.name !== 'SICK'
            ? null
            : `
          <div class="tooltip">
                <strong>${this.formatDate(params.data.healthData.start, params.data.healthData.end)}</strong>
                 ${errorCount > 0 ? `<div>${errorLabel}: ${errorCount}</div>` : ''}
                ${warningCount > 0 ? `<div>${warningLabel}: ${warningCount}</div>` : ''}
          </div>
        `
        },
      }
    },
    isSelectedHour(selectedHour, healthData, firstSickDayIndex, index) {
      if (!selectedHour) {
        return firstSickDayIndex === index
      }
      return DateTime.fromISO(selectedHour).hour === DateTime.fromISO(healthData.start).hour
    },
    seriesData() {
      const data = []
      const firstSickDayIndex = this.data.findIndex(healthData => healthData.status.toUpperCase() === 'SICK')
      this.categories?.forEach(() => {
        for (var i = 0; i < this.data.length; i++) {
          const healthData = this.data[i]
          const typeItem = this.types.find(type => type.name === healthData.status.toUpperCase())
          const totalHours = 1 //getTotalHoursBetweenDates(healthData.start, healthData.end)
          const baseTime = millisFromIsoDate(healthData.start)
          data.push({
            name: typeItem.name,
            value: [0, baseTime, baseTime + totalHours * this.durationPerHour, this.durationPerHour],
            healthData,
            isFuture: this.hasData(new Date(baseTime), healthData),
            isSelected: this.isSelectedHour(this.selectedHour?.start, healthData, firstSickDayIndex, i),
            itemStyle: {
              color: this.hasData(new Date(baseTime), healthData) ? typeItem.noData : typeItem.color,
              stroke: typeItem.stroke,
              seperator: typeItem.seperator,
              selection: typeItem.selection,
              noData: typeItem.noData,
            },
          })
        }
      })
      return data
    },
    xAxisLabelGenerator(params) {
      const inputDate = DateTime.fromISO(params.healthData.start, { setZone: false })
      const startDate = getStartTime(inputDate, 'hour')
      return formatTimePerLocale(startDate, this.uiSettings.time)
    },
  },
}
</script>
