<template>
  <WrapperStyled>
    <TimePeriodSwitcherMolecule :date="sickPeriod" :unit="'hour'" @changeTimePeriod="onChangeTimePeriod" :validDates="sickHoursOfTheDay" />
    <CustomChartAtom
      v-if="!isLoading"
      :data="generateData()"
      :categories="categories"
      :types="types"
      :tooltip="tooltip"
      :seperator="false"
      :showMinSeperatorWithLabel="true"
      @itemClick="onItemClick"
      class="chart"
    />
  </WrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import CustomChartAtom from '@/components/Atomic/Atoms/CustomChartAtom'
import TimePeriodSwitcherMolecule from '../TimePeriodSwitcherMolecule.vue'
import { getTotalSecondsBetweenDates, isDateInFuture, formatTimePerLocale } from '@/utils/time'
import { DateTime } from 'luxon'
import chroma from 'chroma-js'
import { getLocale } from '@/utils/locale'
import { secondsTohhmmss } from '@/utils/filters/time'

const WrapperStyled = styled('div')`
  display: flex;
  flex-direction: column;
  .chart {
    height: 4rem;
  }
  .tooltip {
    text-align: left;
    line-height: 1.5rem;
  }
`
export default {
  inject: ['theme', 'uiSettings'],
  props: {
    isLoading: {
      type: Boolean,
    },
    sickPeriod: {
      type: Object,
      required: true,
    },
    sicknessSummary: {
      type: Object,
      required: true,
    },
    sickHoursOfTheDay: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      categories: ['Hour'],
    }
  },
  components: {
    WrapperStyled,
    CustomChartAtom,
    TimePeriodSwitcherMolecule,
  },
  computed: {
    durationPerSecond() {
      return 1000
    },
    tooltip() {
      return this.tootip()
    },
    types() {
      return [
        {
          name: 'UNKNOWN',
          color: this.theme.colors.atomic.unknownFill,
          border: this.theme.colors.atomic.unknownBorder,
          stroke: this.theme.colors.atomic.unknownBorder,
          seperator: this.theme.colors.atomic.seperator,
          selection: this.theme.colors.atomic.selection,
          noData: this.theme.colors.atomic.noData,
        },
        {
          name: 'SICK',
          color: this.theme.colors.atomic.sickFill,
          border: this.theme.colors.atomic.sickBorder,
          stroke: this.theme.colors.atomic.sickStroke,
          seperator: this.theme.colors.atomic.seperator,
          selection: this.theme.colors.atomic.selection,
          noData: this.theme.colors.atomic.noData,
        },
      ]
    },
  },
  methods: {
    onChangeTimePeriod(params) {
      this.$emit('onHourChange', params)
    },
    hasData(date) {
      return isDateInFuture(date)
    },
    trackEvent(trackingIdPrefix, health, value) {
      const trackingId = trackingIdPrefix + health
      this.$matomo?.trackEvent('Buttons', 'Click', trackingId, value)
    },
    onItemClick(params) {
      const health = params.name.toLowerCase()
      const issue = params
      const trackingData = {
        start: issue.start,
        end: issue.end,
        duration: issue.duration,
      }

      this.trackEvent(`Historical-Asset-Health-Hourly-`, health || '', trackingData)
    },
    tootip() {
      return {
        borderColor: 'transparent',
        backgroundColor: chroma(this.theme.colors.solidBG).alpha(1).css(),
        textStyle: {
          fontSize: 14,
          color: this.theme.colors.text,
        },
        formatter: params => {
          const data = params.data
          const durationOfSickPeriod = this.$t('historicalAssetHealth.sickPeriods.tooltip.duration', {
            duration: secondsTohhmmss(data.duration),
          })

          const startDateTime = DateTime.fromISO(data.start)
          const locale = getLocale(this.uiSettings?.dates)
          const formattedStartDate = startDateTime.setLocale(locale).toFormat('dd MMM yyyy')
          const isSick = data.name.toLowerCase() === 'sick'
          if (isSick) {
            const startTime = formatTimePerLocale(data.start, this.uiSettings.time, locale)
            const endTime = formatTimePerLocale(data.end || DateTime.fromISO(DateTime.now()), this.uiSettings.time, locale)
            const period = `${formattedStartDate}, ${startTime} - ${endTime}`

            return `<div class="tooltip">
            <strong>${period}</strong>
              <br>
            <span>${durationOfSickPeriod}</span>
          </div>`
          } else {
            return null
          }
        },
      }
    },
    getMappedUnknownAndSickPeriods(mainStart, mainEnd, sickPeriods) {
      // Convert main start and end times to Luxon DateTime objects
      const mainStartDT = DateTime.fromISO(mainStart, { zone: 'Europe/Berlin' })
      const mainEndDT = DateTime.fromISO(mainEnd, { zone: 'Europe/Berlin' })

      // Convert sick periods to Luxon DateTime objects, and handle null end times
      const sickPeriodsDT = sickPeriods.map(period => ({
        ...period,
        start: DateTime.fromISO(period.start, { zone: 'utc' }).setZone('Europe/Berlin'),
        end: period.end ? DateTime.fromISO(period.end, { zone: 'utc' }).setZone('Europe/Berlin') : mainEndDT,
      }))

      // Sort sick periods by start time
      sickPeriodsDT.sort((a, b) => a.start - b.start)

      // Initialize array to store periods
      const periods = []

      // Add initial UNKNOWN period if it exists
      if (mainStartDT < sickPeriodsDT[0].start) {
        periods.push({ ...sickPeriodsDT[0], start: mainStartDT, end: sickPeriodsDT[0].start, status: 'UNKNOWN' })
      }

      // Loop through the sick periods
      for (let i = 0; i < sickPeriodsDT.length; i++) {
        // Add the sick period
        periods.push({ ...sickPeriodsDT[i], start: sickPeriodsDT[i].start, end: sickPeriodsDT[i].end, status: 'SICK' })

        // Add the UNKNOWN period between the current sick period and the next sick period
        if (i < sickPeriodsDT.length - 1 && sickPeriodsDT[i].end < sickPeriodsDT[i + 1].start) {
          periods.push({ start: sickPeriodsDT[i].end, end: sickPeriodsDT[i + 1].start, status: 'UNKNOWN' })
        }
      }

      // Add final UNKNOWN period if it exists
      if (sickPeriodsDT[sickPeriodsDT.length - 1].end < mainEndDT) {
        periods.push({ start: sickPeriodsDT[sickPeriodsDT.length - 1].end, end: mainEndDT, status: 'UNKNOWN' })
      }

      return periods.map(period => ({
        ...period,
        start: period.start.toISO(),
        end: period.end.toISO(),
        status: period.status,
      }))
    },

    generateData() {
      if (!this.sicknessSummary && !this.sickPeriod) {
        return
      }
      const healthData = this.getMappedUnknownAndSickPeriods(
        this.sickPeriod?.start,
        this.sickPeriod?.end,
        this.sicknessSummary.sickIntervals,
      )
      return healthData.map(issue => {
        const isSickPeriod = issue.status.toLowerCase() === 'sick'
        const typeItem = isSickPeriod ? this.types[1] : this.types[0]
        const baseTime = DateTime.fromISO(issue.start).toMillis()
        const totalMinutes = getTotalSecondsBetweenDates(issue.start, issue.end)

        return {
          name: typeItem.name,
          start: issue.start,
          end: issue.end,
          duration: issue.duration,
          label: issue.label,
          value: [0, baseTime, baseTime + totalMinutes * this.durationPerSecond, this.durationPerSecond],
          isFuture: this.hasData(new Date(baseTime)),
          itemStyle: {
            color: isDateInFuture(new Date(baseTime)) ? typeItem.noData : typeItem.color,
            stroke: typeItem.stroke,
            border: typeItem.border,
            seperator: typeItem.seperator,
            selection: typeItem.selection,
            noData: typeItem.noData,
          },
        }
      })
    },
  },
}
</script>
